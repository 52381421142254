// import useHover from '@react-hook/hover'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { createContext, Fragment, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import useSWR from 'swr'
import Authenticate from '../components/auth/Authenticate'
import Group from '../components/groups/Group'
import Header from '../components/Header'
import Onboarding from '../components/onboarding/Onboarding'
import { useEngine, useEngineEvent, usePreloadScene, useScene } from '../hooks/game-engine/useEngine'
import { useMultiplayer } from '../hooks/multiplayer/useMultiplayer'
import useHover from '../hooks/useHover'
import { getLayout as getSiteLayout } from './SiteLayout'
import { Transition } from '@headlessui/react'
import Loader from '../components/Loader'
import isMobile from 'is-mobile'

export const EngineContext = createContext('engine')

const mapStateToProps = ({ site }, ownProps) => ({ ...ownProps, site })
const GameLayout = connect(mapStateToProps, null)(({ children, sceneName, heroImage, site, hideHeader }) => {
  // import hooks
  const canvasRef = useRef()
  const router = useRouter()
  const { data: config } = useSWR(isMobile() ? '/engine/config-ds1.json' : '/engine/config.json')

  const isFirstPerson = site.exploreMode === 'firstperson'

  // Start preloading gallery scene into memory
  const { data: engine, error } = useEngine(config, canvasRef)
  const { data: sceneData, progress, error: sceneError } = usePreloadScene(engine, sceneName)
  const { data: scene, error: loadError, mutate } = useScene(engine, sceneData)

  // const isHovering = false;//useHover(headerRef, { enterDelay: 200, leaveDelay: 200 })
  const [headerRef, isOverHeader] = useHover()
  const [popoutRef, isPopoutHovering] = useHover()

  // raise any errors
  if (error || sceneError || loadError) console.error(sceneName, error, sceneError, loadError)

  // Navigate to the path when a user clicks on the item
  useEngineEvent(engine, 'ui:onClick', asset => {
    
    router.push(`${asset.toLowerCase()}`)
  })

  // multiplayer
  useMultiplayer(engine)

  // Listen for in game events to switch between scenes
  useEngineEvent(engine, 'sceneManager:loadScene', url => {
    engine.fire('cameraManager:transition:fadeOut')
    router.push(url)
  })

  return (
    <div className='relative inset-0 w-screen h-screen overflow-hidden'>
      <canvas className='absolute inset-0 w-full h-full pointer-events-auto bg-black' ref={canvasRef} />
      <Transition 
        className='absolute inset-0 w-screen h-screen flex items-center'
        show={!scene}
        enter="filer transition duration-1500 ease-in-out"
        enterFrom="opacity-0 blur-lg "
        enterTo="opacity-100 blur-none"
        leave="filter transition duration-1500 ease-in-out"
        leaveFrom="opacity-100 blur-none"
        leaveTo="opacity-0 blure-lg" >
          <Image priority quality='90' layout='fill' objectFit='cover' quality='100' src={heroImage || '/images/atrium.png'} />
      </Transition>

      <main className='absolute inset-0 pointer-events-none flex items-center justify-end w-screen h-screen md:mx-auto md:container'>
      <Transition 
        // as={Fragment}
        className='absolute inset-0 container mx-auto p-6 h-screen flex items-center'
        show={isFirstPerson && progress < 1}
        enter="filer transition transform duration-1500 ease-in-out"
        enterFrom="translate-x-10 opacity-0 blur-lg "
        enterTo="translate-x-0 opacity-100 blur-none"
        leave="filter transition transform duration-1500 ease-in-out"
        leaveFrom="translate-x-0 opacity-100 blur-none"
        leaveTo="-translate-x-10 opacity-0 blure-lg" >
        <Loader progress={progress}>
          <h1 className='font-display leading-tight text-2xl m-2 font-normal tracking-widest text-gray-50 uppercase'>Loading {/*sceneName.split('-').join(' ')*/}</h1>
        </Loader>
      </Transition>
        <EngineContext.Provider value={{ engine, progress, scene, isHovering: isOverHeader || isPopoutHovering }}>
          {children} 
          <Transition
            show={!!site.popoutState}
            as={Fragment}
            enter="transition md:transform duration-500 ease-in-out"
            enterFrom="translate-x-10 opacity-0 "
            enterTo="translate-x-0 opacity-100"
            leave="transition md:transform duration-500 ease-in-out"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-10 opacity-0"
            >
            <section className='absolute z-50 inset-0 flex items-center justify-end w-screen h-screen md:mx-auto md:container'>
              { 
                (site.popoutState === 'auth' && <Authenticate isHovering={isPopoutHovering} ref={popoutRef} />) ||
                (site.popoutState === 'onboarding' && progress === 1 && <Onboarding isHovering={isPopoutHovering} ref={popoutRef} />) ||
                (site.popoutState === 'groups' && <Group isHovering={isPopoutHovering} ref={popoutRef} />) || null
              }
            </section>
          </Transition>
        </EngineContext.Provider>
      </main>
      
      {!hideHeader && scene && <Header ref={headerRef} className='pointer-events-none' itemColor='text-gray-50' />}
    </div>
  )
})

export const getLayout = (page, props) => getSiteLayout(<GameLayout {...props}>{page}</GameLayout>)

export default GameLayout
