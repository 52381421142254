import { useEffect, useMemo, useRef } from "react";
import { useEngineEvent } from "../game-engine/useEngine"
import jazzicon from '@metamask/jazzicon'
import { IoAccessibility } from "react-icons/io5";
import { resolveHref } from "next/dist/next-server/lib/router/router";

const createUserTexture = (engine, uid, size = 1024) => {
  if (!window.pc) throw new Error('engine has not loaded')

  const addr = uid.slice(2, 10)
  const seed = parseInt(addr, 16)
  const icon = jazzicon(size, Math.round(seed))
    .innerHTML.split('<svg').join('<svg xmlns="http://www.w3.org/2000/svg"')

  const blob = new window.Blob([icon], {type: 'image/svg+xml'})
  const url = URL.createObjectURL(blob)
  const image = document.createElement('img')
  const texture = new pc.Texture(engine.graphicsDevice, {
    width: size,
    height: size,
    format: window.pc.PIXELFORMAT_R8_G8_B8
  })

  image.addEventListener('load', _ => {
    texture.setSource(image)
    URL.revokeObjectURL(url)
  })

  image.src = url

  return texture
}

const lerp = (lhs, rhs, alpha) => ([
  lhs.x + alpha * (rhs[0] - lhs.x),
  lhs.y + alpha * (rhs[1] - lhs.y),
  lhs.z + alpha * (rhs[2] - lhs.z)
])

const addPlayer = (engine, { id, username }, template, root) => {

  console.log('player template', template)
  if (!engine.root.findByName('RemotePlayerLabelUI')) {
    const labelTemplate = engine.assets.find('RemotePlayerLabelUI')
    console.log('label template', labelTemplate)
    const labelRoot = labelTemplate.resource.instantiate()
    labelRoot.name = 'RemotePlayerLabelUI'
    root.addChild(labelRoot)
  }
  
  const instance = template.resource.instantiate()
  instance.username = username
  instance.name = id
  const texture = createUserTexture(engine, id)
  instance.uidTexture = texture
  root.addChild(instance)
  return instance
}

export const useMultiplayer = (engine, {
  templateName = 'Remote Player Template',
  rootName = 'Players' } = {}) => {

  const players = useRef({})
  const root = useMemo(_ => {
    if (engine) {
      let root = engine.root.findByName(rootName)
      if (!root) {
        root = new window.pc.Entity()
        engine.root.addChild(root)
      }
      return root
    }
  }, [engine])

  useEngineEvent(engine, 'player:add', ({ id, username }) => {
    const template = engine.assets.find(templateName)
    if (!template) throw new Error(`No template '${templateName}' found in the asset registry`)

    const player = addPlayer(engine, { id, username }, template, root)
    console.log('HAS POSITION', player.getPosition)
    console.log('player:add', id, template, root, player)
    players.current[id] = player
  })

  useEngineEvent(engine, 'player:remove', ({ id }) => {
    const player = players.current[id]
    console.log('player:remove')
    if (player) {
      player.destroy()
      root.removeChild(player)
      delete players.current[id]
    }
  })

  useEngineEvent(engine, 'player:update', ({ id, state }) => {
    const player = players.current[id]
    const { position, rotation } = state
    // console.log('player:update', position, rotation )
    if(player){
      player._targetPosition = position
      player._targetRotation = rotation
    }
  })

  let ID = useRef(null)
  useEffect(_ => {

    const step = _ => {
      const playersEntitys = Object.values(players.current)

      playersEntitys.forEach(player => {

        if(player._targetPosition && player._targetRotation){
          // smooth lerp the position
          const position = lerp(player.getPosition(), player._targetPosition, 0.1)
          player.setPosition(...position)

          // Smooth lerp the rotation
          const rotation = player.getRotation()
          const tQ = new pc.Quat().setFromEulerAngles(...player._targetRotation)
          rotation.slerp(rotation, tQ, 0.1)
          player.setRotation(rotation)

          // player.fire('updateTransform', { position, rotation })

          // var model = player.findByName('Model');
          // var mesh = model && model.model.meshInstances && model.model.meshInstances[1];
          // // this.materialInstance = mesh.material.clone();
          // // mesh.material = this.materialInstance;
          // // mesh.material.emissiveMap = this.entity.uidTexture.resource;
          // mesh && mesh.material.update();
        }
      })

      ID.current = requestAnimationFrame(step)
    }

    ID.current = requestAnimationFrame(step)
    return _ => cancelAnimationFrame(ID.current)
  })

}
